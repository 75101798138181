import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BackgroundImage from "gatsby-background-image-es5";
import OurMenu from "../img/ourmenu.svg";
import Appetizers from "../img/appetizers.svg";
import Dinners from "../img/dinners.svg";
import Kids from "../img/kids.svg";
import Deserts from "../img/deserts.svg";
import { motion } from "framer-motion";

import AppsJSONData from "../global_data/appetizers.json";
import DinnersJSONData from "../global_data/dinners.json";
import KidsJSONData from "../global_data/kidsMenu.json";
import DesertJSONData from "../global_data/deserts.json";

// appetizers
//const Apps = AppsJSONData.appetizerItems;
let theApps = AppsJSONData.appetizerItems.filter((f) => {
	return f.isActive;
});

const halfApps = Math.ceil(theApps.length / 2);
const firstHalfApps = theApps.splice(0, halfApps);
const secondHalfApps = theApps.splice(-halfApps);

// dinners
//const theDinners = DinnersJSONData.dinnerItems;
let theDinners = DinnersJSONData.dinnerItems.filter((f) => {
	return f.isActive;
});
const halfDinners = Math.ceil(theDinners.length / 2);
const firstHalfDinners = theDinners.splice(0, halfDinners);
const secondHalfDinners = theDinners.splice(-halfDinners);

// kids
//const theKids = KidsJSONData.kidsMenuItems;
let theKids = KidsJSONData.kidsMenuItems.filter((f) => {
	return f.isActive;
});
const halfKids = Math.ceil(theKids.length / 2);
const firstHalfKids = theKids.splice(0, halfKids);
const secondHalfKids = theKids.splice(-halfKids);

let theDeserts = DesertJSONData.desertItems.filter((f) => {
	return f.isActive;
});
const halfDeserts = Math.ceil(theDeserts.length / 2);
const firstHalfDeserts = theDeserts.splice(0, halfDeserts);
const secondHalfDeserts = theDeserts.splice(-halfDeserts);

export const MenuPageTemplate = ({ image }) => (
	<div>
		<BackgroundImage
			fluid={image.childImageSharp.fluid}
			className="full-width-image-container margin-top-0 mb0 menu-banner bg-center"
		>
			<div
				className="mx-auto relative"
				style={{
					backgroundSize: "cover",
					backgroundPosition: "top center",
					background:
						"linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.1))",
					height: "100%",
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					padding: "100px 0",
				}}
			>
				<motion.div
					style={{ originY: 2 }}
					initial="hidden"
					animate="visible"
					variants={{
						hidden: {
							scale: 0.8,
							opacity: 0,
						},
						visible: {
							scale: 1,
							opacity: 1,
							transition: {
								delay: 0.6,
							},
						},
					}}
				>
					<div
						className="has-text-weight-bold is-size-1"
						style={{
							backgroundColor: "rgb(166, 33, 3)",
							color: "white",
							padding: "1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								lineHeight: "1",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								padding: "0px",
								height: "auto",
								position: "relative",
							}}
						>
							<img
								src={OurMenu}
								alt="Buckhorn Supper Club"
								className="mb0 svg-logo-menu"
							/>
						</div>
					</div>
				</motion.div>
			</div>
		</BackgroundImage>

		<section className="hours-reservations specials">
			<div className="container white section watch-video is-clearfix">
				<motion.div
					style={{ originY: 2 }}
					initial="hidden"
					animate="visible"
					variants={{
						hidden: {
							scale: 0.8,
							opacity: 0,
						},
						visible: {
							scale: 1,
							opacity: 1,
							transition: {
								delay: 0.8,
							},
						},
					}}
				>
					<h5 className="menu-disclaimer">
						At this time the menu changes daily, call with questions
					</h5>
					<div
						style={{
							display: "flex",
							lineHeight: "1",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							padding: "50px",
							height: "auto",
							position: "relative",
						}}
					>
						<img
							src={Appetizers}
							alt="Buckhorn Supper Club"
							className="mb0 svg-logo-menu"
						/>
					</div>
				</motion.div>

				<motion.div
					style={{ originY: 2 }}
					initial="hidden"
					animate="visible"
					variants={{
						hidden: {
							scale: 0.8,
							opacity: 0,
						},
						visible: {
							scale: 1,
							opacity: 1,
							transition: {
								delay: 1,
							},
						},
					}}
				>
					<hr className="style18" />
				</motion.div>
				<motion.div
					style={{ originY: 2 }}
					initial="hidden"
					animate="visible"
					variants={{
						hidden: {
							scale: 0.8,
							opacity: 0,
						},
						visible: {
							scale: 1,
							opacity: 1,
							transition: {
								delay: 1.2,
							},
						},
					}}
				>
					<div className="columns">
						<div className="column menu-col is-6-desktop" data-aos="fade">
							<table className="specials-table">
								<tbody>
									{firstHalfApps.map((data, index) => {
										return (
											<>
												<tr
													key={`content_item_${index}`}
													className="nameandprice"
												>
													<td classname="dotStyle">
														<span className="menuStyle bgcover">
															{data.name}
														</span>
													</td>
													<td className="dotStyle">
														<span className="menuStyle bgcover">
															{data.price}
														</span>
													</td>
												</tr>
												<tr
													className="description"
													style={{ textAlign: "left" }}
												>
													<td colSpan={2}>{data.description}</td>
												</tr>
											</>
										);
									})}
								</tbody>
							</table>
						</div>
						<div
							className="column menu-col is-6-desktop has-text-centered"
							data-aos="fade-up"
						>
							<table className="specials-table">
								<tbody>
									{secondHalfApps.map((data, index) => {
										return (
											<>
												<tr
													key={`content_item_${index}`}
													className="nameandprice"
												>
													<td classname="dotStyle">
														<span className="menuStyle bgcover">
															{data.name}
														</span>
													</td>
													<td className="dotStyle">
														<span className="menuStyle bgcover">
															{data.price}
														</span>
													</td>
												</tr>
												<tr
													className="description"
													style={{ textAlign: "left" }}
												>
													<td colSpan={2}>{data.description}</td>
												</tr>
											</>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</motion.div>
				<hr className="style18 flip-vert" />

				<div
					style={{
						display: "flex",
						lineHeight: "1",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						padding: "50px",
						height: "auto",
						position: "relative",
					}}
				>
					<img
						src={Dinners}
						alt="Buckhorn Supper Club"
						className="mb0 svg-logo-menu"
					/>
				</div>

				<hr className="style18" />

				<div className="columns is-8">
					<div className="column menu-col is-6-desktop" data-aos="fade">
						<table className="specials-table">
							<tbody>
								{firstHalfDinners.map((data, index) => {
									return (
										<>
											<tr
												key={`content_item_${index}`}
												className="nameandprice"
											>
												<td classname="dotStyle">
													<span className="menuStyle bgcover">{data.name}</span>
												</td>
												<td className="dotStyle">
													<span className="menuStyle bgcover">
														{data.price}
													</span>
												</td>
											</tr>
											<tr className="description" style={{ textAlign: "left" }}>
												<td colSpan={2}>{data.description}</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					</div>
					<div
						className="column menu-col is-6-desktop has-text-centered"
						data-aos="fade-up"
					>
						<table className="specials-table">
							<tbody>
								{secondHalfDinners.map((data, index) => {
									return (
										<>
											<tr
												key={`content_item_${index}`}
												className="nameandprice"
											>
												<td classname="dotStyle">
													<span className="menuStyle bgcover">{data.name}</span>
												</td>
												<td className="dotStyle">
													<span className="menuStyle bgcover">
														{data.price}
													</span>
												</td>
											</tr>
											<tr className="description" style={{ textAlign: "left" }}>
												<td colSpan={2}>{data.description}</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<hr className="style18 flip-vert" />
				<div
					style={{
						display: "flex",
						lineHeight: "1",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						padding: "50px",
						height: "auto",
						position: "relative",
					}}
				>
					<img
						src={Kids}
						alt="Buckhorn Supper Club"
						className="mb0 svg-logo-menu"
					/>
				</div>

				<hr className="style18" />

				<div className="columns">
					<div className="column menu-col is-6-desktop" data-aos="fade">
						<table className="specials-table">
							<tbody>
								{firstHalfKids.map((data, index) => {
									return (
										<>
											<tr
												key={`content_item_${index}`}
												className="nameandprice"
											>
												<td classname="dotStyle">
													<span className="menuStyle bgcover">{data.name}</span>
												</td>
												<td className="dotStyle">
													<span className="menuStyle bgcover">
														{data.price}
													</span>
												</td>
											</tr>
											<tr className="description" style={{ textAlign: "left" }}>
												<td colSpan={2}>{data.description}</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					</div>
					<div
						className="column menu-col is-6-desktop has-text-centered"
						data-aos="fade-up"
					>
						<table className="specials-table">
							<tbody>
								{secondHalfKids.map((data, index) => {
									return (
										<>
											<tr
												key={`content_item_${index}`}
												className="nameandprice"
											>
												<td classname="dotStyle">
													<span className="menuStyle bgcover">{data.name}</span>
												</td>
												<td className="dotStyle">
													<span className="menuStyle bgcover">
														{data.price}
													</span>
												</td>
											</tr>
											<tr className="description" style={{ textAlign: "left" }}>
												<td colSpan={2}>{data.description}</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<hr className="style18 flip-vert" />

				<div
					style={{
						display: "flex",
						lineHeight: "1",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						padding: "50px",
						height: "auto",
						position: "relative",
					}}
				>
					<img
						src={Deserts}
						alt="Buckhorn Supper Club"
						className="mb0 svg-logo-menu"
					/>
				</div>

				<hr className="style18" />

				<div className="columns">
					<div className="column menu-col is-6-desktop" data-aos="fade">
						<table className="specials-table">
							<tbody>
								{firstHalfDeserts.map((data, index) => {
									return (
										<>
											<tr
												key={`content_item_${index}`}
												className="nameandprice"
											>
												<td classname="dotStyle">
													<span className="menuStyle bgcover">{data.name}</span>
												</td>
												<td className="dotStyle">
													<span className="menuStyle bgcover">
														{data.price}
													</span>
												</td>
											</tr>
											<tr className="description" style={{ textAlign: "left" }}>
												<td colSpan={2}>{data.description}</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					</div>
					<div
						className="column menu-col is-6-desktop has-text-centered"
						data-aos="fade-up"
					>
						<table className="specials-table">
							<tbody>
								{secondHalfDeserts.map((data, index) => {
									return (
										<>
											<tr
												key={`content_item_${index}`}
												className="nameandprice"
											>
												<td classname="dotStyle">
													<span className="menuStyle bgcover">{data.name}</span>
												</td>
												<td className="dotStyle">
													<span className="menuStyle bgcover">
														{data.price}
													</span>
												</td>
											</tr>
											<tr className="description" style={{ textAlign: "left" }}>
												<td colSpan={2}>{data.description}</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<hr className="style18 flip-vert" />
			</div>
		</section>
	</div>
);

MenuPageTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	description: PropTypes.string,
};

const MenuPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<MenuPageTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				description={frontmatter.description}
			/>
		</Layout>
	);
};

MenuPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default MenuPage;

export const MenuPageQuery = graphql`
	query MenuPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				description
			}
		}
	}
`;
